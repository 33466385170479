import React from 'react';
import Spline from '@splinetool/react-spline';
import NavBar from '../elements/NavBar';
import '../styles/Home.css';

function Home() {
    return(
        <body className='appBody'>
            <NavBar></NavBar>
            <div className='welcome'>
                <div className='animated'>
                    <Spline scene="https://prod.spline.design/qvvOn71JwBWstThQ/scene.splinecode" />
                </div>
                <div className='textSection'>
                    <h1 className='welcomeTitle'>Empowering humans through software</h1>
                    <button onClick={ buttonFunction } className='discoverButton'>Discover how</button>
                </div>
            </div>
        </body>
    );
}

function buttonFunction() {
    console.log("Button was pressed")
}

export default Home;