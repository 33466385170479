import React from 'react';
import '../styles/NavBar.css';
import Logo from '../logo.svg';

function NavBar() {
    return(
        <div className='navBody'>
            <img src={ Logo } alt="HG Mercuria official logo" className='navLogo' />
            <div className='links'>
                <a href="/">Home</a>
                <a href="/">Products</a>
                <a className="contactButton" href='/contact'>Contact</a>
            </div>
        </div>
    )
}

export default NavBar